import { render, staticRenderFns } from "./AboutCommercial.vue?vue&type=template&id=5b9f7469&scoped=true&"
import script from "./AboutCommercial.vue?vue&type=script&lang=js&"
export * from "./AboutCommercial.vue?vue&type=script&lang=js&"
import style0 from "./AboutCommercial.vue?vue&type=style&index=0&id=5b9f7469&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b9f7469",
  null
  
)

export default component.exports